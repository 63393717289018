import React from "react";
import { Routes, Route } from "react-router-dom";
import MarketplaceSidebar from "./MarketplaceSidebar";
import BlogsPage from "./BlogsPage";
import NewsPage from "./NewsPage";
import DashboardPage from "./DashboardPage";
import BlogDetail from "./BlogDetail";
import NewsDetail from "./NewsDetail";

const MarketplacePage: React.FC = () => {
  return (
    <div className="flex h-screen bg-black text-white">
      {/* Sidebar */}
      <div className="w-64 fixed h-full">
        <MarketplaceSidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-64">
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/blogs/:id" element={<BlogDetail />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/dashboard" element={<DashboardPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default MarketplacePage;
