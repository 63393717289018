import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface NewsArticle {
  id: number;
  title: string;
  description: string;
  image: string;
  date: string;
}

const NewsPage: React.FC = () => {
  const [news, setNews] = useState<NewsArticle[]>([]);
  const navigate = useNavigate();

  // Simulating an API call to fetch news
  useEffect(() => {
    const fetchNews = async () => {
      const data = [
        {
          id: 1,
          title: "TOTLE Raises $10M in Funding",
          description:
            "TOTLE successfully raised $10M to revolutionize education using AI and machine learning.",
          image: "/images/news1.jpg",
          date: "January 15, 2025",
        },
        {
          id: 2,
          title: "New Features Announced for Educators",
          description:
            "TOTLE introduces new features to help educators manage their sessions more effectively.",
          image: "/images/news2.jpg",
          date: "February 10, 2025",
        },
        {
          id: 3,
          title: "TOTLE Expands to Southeast Asia",
          description:
            "TOTLE expands operations to Southeast Asia, providing opportunities for educators and learners.",
          image: "/images/news3.jpg",
          date: "March 5, 2025",
        },
      ];
      setNews(data);
    };
    fetchNews();
  }, []);

  // Navigate to individual news details
  const handleReadMore = (id: number) => {
    navigate(`/marketplace/news/${id}`);
  };

  return (
    <div className="flex-1 flex flex-col bg-black/80 text-white">
      <div className="p-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-extrabold bg-gradient-to-r from-tangering to-orange-500 bg-clip-text text-transparent mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          News
        </motion.h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {news.map((article) => (
            <motion.div
              key={article.id}
              className="bg-white text-black rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src={article.image}
                alt={article.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-xl font-bold text-tangering mb-2">
                  {article.title}
                </h2>
                <p className="text-gray-600 mb-2">{article.description}</p>
                <p className="text-sm text-gray-400">{article.date}</p>
                <button
                  onClick={() => handleReadMore(article.id)}
                  className="text-tangering font-semibold hover:text-orange-500 transition duration-300 mt-4"
                >
                  Read More →
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
