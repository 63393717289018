import React from "react";
import { Outlet } from "react-router-dom";
import EndeavourSidebar from "./Sidebar/EndeavourSidebar";
import styles from "./EndeavourPlatform.module.css";

const EndeavourPlatform: React.FC = () => {
  return (
    <div className={styles.endeavourPlatform}>
  
      {/* Sidebar and Main Content Layout */}
      <div className={styles.layout}>
        <EndeavourSidebar />
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default EndeavourPlatform;
