import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

interface Blog {
  id: number;
  title: string;
  description: string;
  image: string;
}

const BlogsPage: React.FC = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const navigate = useNavigate();

  // Simulating an API call to fetch blogs
  useEffect(() => {
    const fetchBlogs = async () => {
      const data = [
        {
          id: 1,
          title: "How to Teach Online",
          description: "Learn the best strategies to teach effectively online.",
          image: "/images/blog1.jpg",
        },
        {
          id: 2,
          title: "Top Skills for 2025",
          description: "Discover the skills educators need to stay ahead.",
          image: "/images/blog2.jpg",
        },
        {
          id: 3,
          title: "Creating Engaging Sessions",
          description: "Tips and tricks to make your teaching sessions fun.",
          image: "/images/blog3.jpg",
        },
      ];
      setBlogs(data);
    };
    fetchBlogs();
  }, []);

  // Navigate to individual blog details
  const handleReadMore = (id: number) => {
    navigate(`/marketplace/blogs/${id}`);
  };

  return (
    <div className="flex-1 flex flex-col bg-black/80 text-white">
      <div className="p-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-extrabold bg-gradient-to-r from-tangering to-orange-500 bg-clip-text text-transparent mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Blogs
        </motion.h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogs.map((blog) => (
            <motion.div
              key={blog.id}
              className="bg-white text-black rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-xl font-bold text-tangering mb-2">
                  {blog.title}
                </h2>
                <p className="text-gray-600 mb-4">{blog.description}</p>
                <button
                  onClick={() => handleReadMore(blog.id)}
                  className="text-tangering font-semibold hover:text-orange-500 transition duration-300"
                >
                  Read More →
                </button>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
