import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaBook, FaNewspaper } from "react-icons/fa";

const MarketplaceSidebar: React.FC = () => {
  return (
    <div className="h-full w-64 bg-black/60 backdrop-blur-lg fixed top-0 left-0 z-50 shadow-lg">
      <div className="flex flex-col h-full">
        {/* Logo */}
        <div className="p-6">
          <h1 className="text-3xl font-bold text-white">TOTLE</h1>
        </div>

        {/* Navigation Links */}
        <nav className="flex flex-col space-y-4 px-6">
          <NavLink
            to="/marketplace"
            className={({ isActive }) =>
              `flex items-center px-4 py-3 rounded-lg space-x-3 text-lg font-medium ${
                isActive ? "bg-black/40 text-tangering" : "text-gray-300 hover:text-white"
              }`
            }
          >
            <FaHome className="text-xl" />
            <span>Home</span>
          </NavLink>
          <NavLink
            to="/marketplace/blogs"
            className={({ isActive }) =>
              `flex items-center px-4 py-3 rounded-lg space-x-3 text-lg font-medium ${
                isActive ? "bg-black/40 text-tangering" : "text-gray-300 hover:text-white"
              }`
            }
          >
            <FaBook className="text-xl" />
            <span>Blogs</span>
          </NavLink>
          <NavLink
            to="/marketplace/news"
            className={({ isActive }) =>
              `flex items-center px-4 py-3 rounded-lg space-x-3 text-lg font-medium ${
                isActive ? "bg-black/40 text-tangering" : "text-gray-300 hover:text-white"
              }`
            }
          >
            <FaNewspaper className="text-xl" />
            <span>News</span>
          </NavLink>
      
        </nav>
      </div>
    </div>
  );
};

export default MarketplaceSidebar;
