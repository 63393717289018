import React from "react";
import { Outlet } from "react-router-dom";
import TeachSidebar from "./Sidebar/TeachSidebar";
import styles from "./TeachPlatform.module.css";

const TeachPlatform: React.FC = () => {
  return (
    <div className={styles.teachPlatform}>


      {/* Sidebar and Main Content Layout */}
      <div className={styles.layout}>
        <TeachSidebar />
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default TeachPlatform;
