import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

interface NavbarProps {
  isLoggedIn: boolean; // Determine if the user is logged in
  handleLogout: () => void; // Logout function
}

const Navbar: React.FC<NavbarProps> = ({ isLoggedIn, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setIsOpen(false); // Close mobile menu when a link is clicked
  };

  const linkClass = (isActive: boolean) =>
    `transition-all duration-300 dark:text-white hover:text-tangering ${
      isActive ? "text-tangering" : isScrolled ? "text-gray-700" : "text-gray-400"
    }`;

  const loggedInLinks = (
    <>
      <NavLink to="/marketplace" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Marketplace
      </NavLink>
      <NavLink to="/teach" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Teach
      </NavLink>
      <NavLink to="/learn" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Learn
      </NavLink>
      <NavLink to="/endeavour" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Endeavour
      </NavLink>
      <NavLink to="/profile" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Profile
      </NavLink>
      <button
        onClick={handleLogout}
        className="bg-red-500 text-white py-2 px-6 rounded-lg hover:bg-red-600 transition-all duration-300 flex items-center justify-center"
      >
        Logout
      </button>
    </>
  );

  const loggedOutLinks = (
    <>
      <NavLink to="/" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Home
      </NavLink>
      <NavLink to="/about" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        About Us
      </NavLink>
      <NavLink to="/product" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Product
      </NavLink>
      <NavLink to="/careers" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Careers
      </NavLink>
      <NavLink to="/contact" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
        Contact
      </NavLink>
      <NavLink
        to="/auth"
        onClick={handleLinkClick}
        className="bg-tangering text-white py-2 px-6 rounded-lg hover:bg-orange-600 transition-all duration-300 flex items-center justify-center"
      >
        Sign Up
      </NavLink>
    </>
  );

  return (
    <nav
      className={`fixed w-full z-20 top-0 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg" : "bg-transparent"
      } dark:bg-gray-900`}
    >
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* Logo */}
        <NavLink
          to="/"
          className="text-3xl font-bold text-tangering dark:text-white"
          onClick={handleLinkClick}
        >
          TOTLE
        </NavLink>

        {/* Desktop Links */}
        <div className="hidden md:flex items-center space-x-8">{isLoggedIn ? loggedInLinks : loggedOutLinks}</div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? (
            <FaTimes className="text-gray-700 dark:text-white w-6 h-6 cursor-pointer" />
          ) : (
            <FaBars className="text-gray-700 dark:text-white w-6 h-6 cursor-pointer" />
          )}
        </div>

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 left-0 w-full h-full bg-white dark:bg-gray-900 z-30 transform transition-transform duration-500 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="absolute top-4 right-4">
            <FaTimes
              className="text-gray-700 dark:text-white w-6 h-6 cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="flex flex-col items-center justify-center space-y-8 mt-24">
            {isLoggedIn ? loggedInLinks : loggedOutLinks}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
