// import React, { useState } from "react";
// import {  Routes, Route, useLocation } from "react-router-dom";
// import AuthForm from './components/Auth/AuthForm';
// import NotFoundPage from "../src/pages/NotFoundPage";
// import PlatformPage from "./pages/PlatformPage/PlatformPage";
// import MarketplacePage from "./pages/marketplace/MarketplacePage";
// import TeachPage from "./pages/TeachingPlatform/TeachPage";
// import LearnPage from "./pages/LearnPlatform/LearnPage";
// import EndeavourPage from "./pages/EndeavourPlatform/EndeavourPage";
// import Profile from "./components/Profile/Profile";
// import Navbar from "./pages/Navbar/Navbar";
// import Home from './pages/Home/Home';
// import AboutUs from './pages/AboutUs/AboutUs';
// import VisionMission from './pages/Vision/Vision';
// import Testimonials from './pages/TestimonialsSection/TestimonialsSection'; 
// import Product from './pages/Product/Product';
// import Team from './pages/Team/Team';
// import Blog from './pages/Blog/Blog';
// import Careers from './pages/Careers/Careers';
// import Investors from './pages/Investors/Investors';
// import Contact from './pages/Contact/Contact';
// import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';

// function App() {

//   const location = useLocation(); // Access the current route
//   const noNavbarRoutes = ["/auth", "/forgot-password", "/login", "/signup"];
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const handleLogout = () => {
//     setIsLoggedIn(false);
//     window.location.href = "/auth"; // Redirect to login page
//   };



//   return (
//     <>
//   {!noNavbarRoutes.includes(location.pathname) && (
//         <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
//       )}
//             <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<AboutUs />} />
//         <Route path="/vision" element={<VisionMission />} />
//         <Route path="/testimonials" element={<Testimonials />} />
//         <Route path="/product" element={<Product />} />
//         <Route path="/team" element={<Team />} />
//         <Route path="/careers" element={<Careers />} />
//         <Route path="/investors" element={<Investors />} />
//         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
//         <Route path="/blog" element={<Blog />} />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/auth" element={<AuthForm />} />
//         <Route path="*" element={<NotFoundPage />} />
//         <Route path="/profile" element={<Profile />} />
//         <Route path="/platform" element={<PlatformPage />} />
//         <Route path="/marketplace/*" element={<MarketplacePage />} />
//         <Route path="/teach/*" element={<TeachPage />} />
//         <Route path="/learn/*" element={<LearnPage />} />
//         <Route path="/endeavour/*" element={<EndeavourPage />} />
//       </Routes>
//     </>

//   );
// }

// export default App;


import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AuthForm from "./components/Auth/AuthForm";
import NotFoundPage from "./pages/NotFoundPage";
import Navbar from "./pages/Navbar/Navbar";
import Profile from "./components/Profile/Profile";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import VisionMission from "./pages/Vision/Vision";
import Testimonials from "./pages/TestimonialsSection/TestimonialsSection";
import Product from "./pages/Product/Product";
import Team from "./pages/Team/Team";
import Blog from "./pages/Blog/Blog";
import Careers from "./pages/Careers/Careers";
import Investors from "./pages/Investors/Investors";
import Contact from "./pages/Contact/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

// Private Routes
import PlatformPage from "./pages/PlatformPage/PlatformPage";
import MarketplacePage from "./pages/marketplace/MarketplacePage";
import TeachPage from "./pages/TeachingPlatform/TeachPage";
import LearnPage from "./pages/LearnPlatform/LearnPage";
import EndeavourPage from "./pages/EndeavourPlatform/EndeavourPage";

function App() {
  const location = useLocation(); // Access the current route
  const noNavbarRoutes = ["/auth", "/forgot-password", "/login", "/signup"]; // Routes without Navbar
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => JSON.parse(localStorage.getItem("isLoggedIn") || "false") // Initialize from localStorage
  );

  useEffect(() => {
    localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);
  // Logout Handler
  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");

    window.location.href = "/auth"; // Redirect to login page
  };

  // Protected Route Wrapper
  const PrivateRoute = ({ element }: { element: JSX.Element }) => {
    return isLoggedIn ? element : <Navigate to="/auth" />;
  };

  return (
    <>
      {/* Show Navbar only on certain routes */}
      {!noNavbarRoutes.includes(location.pathname) && (
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
      )}

      {/* Route Definitions */}
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/vision" element={<VisionMission />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/product" element={<Product />} />
        <Route path="/team" element={<Team />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/auth" element={<AuthForm />} />

        {/* Private Routes */}
        <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
        <Route path="/platform" element={<PrivateRoute element={<PlatformPage />} />} />
        <Route path="/marketplace/*" element={<PrivateRoute element={<MarketplacePage />} />} />
        <Route path="/teach/*" element={<PrivateRoute element={<TeachPage />} />} />
        <Route path="/learn/*" element={<PrivateRoute element={<LearnPage />} />} />
        <Route path="/endeavour/*" element={<PrivateRoute element={<EndeavourPage />} />} />

        {/* Catch-All Route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;

