import React from "react";
// import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const NewsDetail: React.FC = () => {
  // const { id } = useParams<{ id: string }>();

  // Simulated news article details
  const newsArticle = {
    title: "TOTLE Raises $10M in Funding",
    content: `
      TOTLE has successfully raised $10 million in Series A funding to continue 
      its mission of revolutionizing education. This funding will help enhance 
      the platform's AI capabilities, expand into new markets, and deliver better 
      features for educators and learners worldwide.
    `,
    image: "/images/news1.jpg",
    date: "January 15, 2025",
  };

  return (
    <div className="flex-1 flex flex-col bg-black/80 text-white">
      <div className="p-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-extrabold bg-gradient-to-r from-tangering to-orange-500 bg-clip-text text-transparent mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {newsArticle.title}
        </motion.h1>
        <div className="relative">
          <img
            src={newsArticle.image}
            alt={newsArticle.title}
            className="w-full h-96 object-cover rounded-lg shadow-lg mb-6"
          />
          <p className="text-sm text-gray-400 mb-4">{newsArticle.date}</p>
          <p className="text-lg text-gray-300">{newsArticle.content}</p>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
