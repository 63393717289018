import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import marketPlaceImg from "../../images/marketplace.jpg";
import teachImg from "../../images/teacher.jpg";
import learnImg from "../../images/learn.jpg";
import endeavourImg from "../../images/endeavour.jpg";

const platforms = [
  {
    title: "Marketplace",
    description:
      "Explore trending topics, analytics, and available educators. Find a vibrant marketplace of educational tools and resources.",
    image: marketPlaceImg,
    link: "/marketplace",
    buttonText: "Enter Marketplace",
  },
  {
    title: "Teacher Platform",
    description:
      "Join or explore the Teachers Platform for educators of all kinds. Connect with peers, access resources, and expand your knowledge base.",
    image: teachImg,
    link: "/teach",
    buttonText: "Enter Teacher Platform",
  },
  {
    title: "Learner Platform",
    description:
      "Your personalized learning dashboard and sessions. Access tailored resources, track progress, and achieve your learning goals.",
    image: learnImg,
    link: "/learn",
    buttonText: "Enter Learner Platform",
  },
  {
    title: "Endeavour Platform",
    description:
      "Participate in competitions, portfolio building, and projects. Showcase your skills, collaborate with others, and grow professionally.",
    image: endeavourImg,
    link: "/endeavour",
    buttonText: "Enter Endeavour Platform",
  },
];

const PlatformPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const handleNavigation = (link: string) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="bg-gray-100 min-h-screen py-16">
      <div className="container mx-auto px-6 text-center">
        {/* Header Section */}
        <h1 className="text-4xl lg:text-5xl font-extrabold text-tangering mb-8">
          Explore Your Platform
        </h1>
        <p className="text-lg text-gray-700 mb-12">
          Discover tools and resources tailored to your needs. Whether you're a learner,
          teacher, or innovator, there's a platform waiting for you.
        </p>

        {/* Platform Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {platforms.map(({ title, description, image, link, buttonText }, index) => (
            <div
              key={title}
              className="group bg-white rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 overflow-hidden"
            >
              {/* Image Section */}
              <div
                className="relative h-56 bg-cover bg-center"
                style={{
                  backgroundImage: `url(${image})`,
                }}
              >
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent group-hover:from-black/80 transition-all duration-300"></div>
                <h3 className="absolute bottom-4 left-4 text-lg font-bold text-white z-10">
                  {title}
                </h3>
              </div>

              {/* Text Section */}
              <div className="p-6">
                <p className="text-gray-600 mb-4">{description}</p>
                <button
                  onClick={() => handleNavigation(link)}
                  className="inline-flex items-center text-tangering font-bold hover:text-orange-600 transition duration-300"
                >
                  {buttonText} <FaArrowRight className="ml-2" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlatformPage;
