import React from "react";
// import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const BlogDetail: React.FC = () => {
  // const { id } = useParams<{ id: string }>();

  // Simulate fetching blog details
  const blog = {
    title: "How to Teach Online",
    content: `
      Teaching online has become increasingly popular, and here are some tips
      to make your sessions engaging and impactful:
      - Use interactive tools like polls and quizzes.
      - Keep sessions short and to the point.
      - Focus on creating a personal connection with students.
    `,
    image: "/images/blog1.jpg",
  };

  return (
    <div className="flex-1 flex flex-col bg-black/80 text-white">
      <div className="p-8">
        <motion.h1
          className="text-4xl sm:text-5xl font-extrabold bg-gradient-to-r from-tangering to-orange-500 bg-clip-text text-transparent mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {blog.title}
        </motion.h1>
        <div className="relative">
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-96 object-cover rounded-lg shadow-lg"
          />
          <p className="text-lg text-gray-300 mt-6">{blog.content}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
