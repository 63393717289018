import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";

const LoginForm: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  // const [isOtpStep, setIsOtpStep] = useState(false); // OTP step
  const [otp, setOtp] = useState(""); // OTP input state
  const [emailOrMobile, setEmailOrMobile] = useState(""); // Email or mobile input for password recovery
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false); // Password recovery form toggle
  const [isOtpForRecovery, setIsOtpForRecovery] = useState(false); // OTP step for recovery
  const navigate = useNavigate();
  // let backendUrl = "http://localhost:5000";
  let backendUrl = "https://totle-webisite-backend.onrender.com";
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    return newErrors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async(e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
  
      const data = await response.json();
  
      if (data.error) {
        alert(data.message || "Login failed. Please try again.");
      } else {
        alert("Login successful!");
        // Store the token in localStorage or context
        localStorage.setItem("token", data.token);
        navigate("/teach")
        
        // console.log("User Data:", data.user);
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again later.");
    }
  

    console.log("Login Data:", formData);

    setFormData({
      email: "",
      password: "",
    });
  };

  const handlePasswordRecoverySubmit = () => {
    if (!emailOrMobile) {
      alert("Please provide your email or mobile number.");
      return;
    }

    console.log("Initiating password recovery for:", emailOrMobile);
    setIsOtpForRecovery(true); // Move to OTP step
  };

  const handleSSOLogin = (provider: string) =>{
    if(provider === "Google"){
      window.location.href = `${backendUrl}/auth/google`;
    }
  }

  const handleOtpSubmitForRecovery = () => {
    if (otp === "123456") {
      alert("OTP verified! You can now reset your password.");
      setIsPasswordRecovery(false);
      setIsOtpForRecovery(false);
      setEmailOrMobile("");
      setOtp("");
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  return (
    <div className="px-4 sm:px-7 md:px-8">
      {/* Login Form */}
      {!isPasswordRecovery && !isOtpForRecovery && (
        <>
          <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
            >
              Login
            </button>
          </form>

          {/* Forgot Password */}
          <div className="mt-4 text-center">
            <button
              onClick={() => setIsPasswordRecovery(true)}
              className="text-blue-600 hover:underline"
            >
              Forgot Password?
            </button>
          </div>
        </>
      )}
      <div className="mt-6 text-center">
            <p className="text-gray-500">Or</p>
            <div className="flex justify-center space-x-4 mb-4 mt-4">
              <button
                onClick={() => handleSSOLogin("Google")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                Google
              </button>
              <button
                onClick={() => handleSSOLogin("Meta")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                Meta
              </button>
              <button
                onClick={() => handleSSOLogin("X")}
                className="bg-gray-200 p-2 rounded-md hover:bg-gray-300"
              >
                X
              </button>
            </div>
          </div>

      {/* Password Recovery Form */}
      {isPasswordRecovery && !isOtpForRecovery && (
        <div className="p-6">
          <h3 className="text-lg font-bold mb-4 text-center">Recover Password</h3>
          <p className="text-gray-600 mb-4 text-center">
            Enter your email or mobile number to receive a verification OTP.
          </p>
          <input
            type="text"
            placeholder="Enter Email or Mobile"
            value={emailOrMobile}
            onChange={(e) => setEmailOrMobile(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-4"
          />
          <button
            onClick={handlePasswordRecoverySubmit}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Send OTP
          </button>
          <button
            onClick={() => setIsPasswordRecovery(false)}
            className="block mt-4 text-gray-600 hover:underline text-sm mx-auto"
          >
            Back to Login
          </button>
        </div>
      )}

      {/* OTP Input for Password Recovery */}
      {isOtpForRecovery && (
        <div className="p-6">
          <h3 className="text-lg font-bold mb-4 text-center">Verify OTP</h3>
          <p className="text-gray-600 mb-4 text-center">
            OTP sent to <strong>{emailOrMobile}</strong>.
          </p>
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-4"
          />
          <button
            onClick={handleOtpSubmitForRecovery}
            className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          >
            Verify OTP
          </button>
          <button
            onClick={() => {
              setIsOtpForRecovery(false);
              setIsPasswordRecovery(true);
              setOtp("");
            }}
            className="block mt-4 text-gray-600 hover:underline text-sm mx-auto"
          >
            Back to Recovery
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
