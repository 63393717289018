import React, { useEffect } from "react";
import AOS from "aos"; // for scroll animations
import "aos/dist/aos.css";
import { FaUsers, FaGlobe, FaBrain } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { NavLink, useNavigate } from "react-router-dom";
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa'; // Icons for social media
import InvestorImage from '../../images/investor.jpg';

const Investors = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-gray-100 pt-20 text-center">
      {/* Main Header */}
      <h2 className="text-4xl font-bold pt-10 mb-8" data-aos="fade-up">
        Transforming Education: Invest in TOTLE
      </h2>

      {/* About TOTLE */}
      <section className="max-w-5xl mx-auto mb-16 px-4">
        <p className="text-lg text-gray-700 mb-8" data-aos="fade-up" data-aos-delay="200">
          TOTLE is revolutionizing education by leveraging AI-powered platforms
          that personalize learning, empower educators, and provide scalable
          solutions for global impact. With a rapidly growing user base, a flexible
          pay-per-topic model, and a mission to bridge the gap between education
          and real-world application, TOTLE is poised for exponential growth.
        </p>
      </section>

      {/* Market Opportunity Section */}
      <section className="py-16 bg-gradient-to-r from-tangering to-orange-500 text-white"
       style={{
        backgroundImage: `url(${InvestorImage})`,
      }}>
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-6" data-aos="fade-up">
            A $200 Billion Opportunity
          </h3>
          <p className="text-lg mb-8 text-white" data-aos="fade-up" data-aos-delay="200">
            The EdTech market is on a path to surpass $200 billion by 2028. With
            AI personalization at the core, TOTLE is well-positioned to become a
            key player in this high-growth industry.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div data-aos="fade-up" data-aos-delay="300">
              <h4 className="text-2xl font-bold mb-4">Scalable AI Infrastructure</h4>
              <p className="text-white">
                TOTLE’s architecture can support millions of users globally, offering
                adaptability and efficiency to address the diverse needs of learners
                and educators.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
              <h4 className="text-2xl font-bold mb-4">Market Differentiation</h4>
              <p className="text-white">
                Unlike traditional subscription models, our pay-per-topic approach
                drives accessibility, affordability, and a competitive edge in retaining users.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Financial Highlights Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-6 text-tangering" data-aos="fade-up">
            Financial Highlights & Growth
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Revenue Growth */}
            <div
              className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h4 className="text-xl font-bold text-tangering mb-4">Revenue Growth</h4>
              <p>
                Projected to grow by 200% annually as TOTLE captures market share with
                its unique AI-driven solutions.
              </p>
            </div>

            {/* Expansion Plans */}
            <div
              className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <h4 className="text-xl font-bold text-blue-500 mb-4">Global Expansion</h4>
              <p>
                Plans to expand into Southeast Asia and Africa, targeting over 10 million
                users in the next five years.
              </p>
            </div>

            {/* Profitability */}
            <div
              className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <h4 className="text-xl font-bold text-green-500 mb-4">Profitability</h4>
              <p>
                TOTLE is on track to achieve profitability within 24 months by optimizing
                costs and maximizing learning outcomes.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Impact Metrics */}
      <section className="pb-14 bg-white">
        <h2
          className="text-4xl font-semibold text-gray-800 mb-12"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          Impact Metrics
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="500">
            <FaUsers className="text-4xl text-tangering mb-4 transition-transform duration-300 group-hover:scale-110" />
            <div className="w-32 h-32">
              <CircularProgressbar
                value={0}
                text={`0%`}
                styles={buildStyles({
                  pathColor: "#FF7043",
                  textColor: "#FF7043",
                  trailColor: "#eee",
                })}
              />
            </div>
            <p className="text-gray-600 mt-4">User Growth</p>
            <p className="text-gray-500 mt-2">Beta product goes live soon!</p>
          </div>

          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="600">
            <FaGlobe className="text-4xl text-blue-500 mb-4 transition-transform duration-300 group-hover:scale-110" />
            <div className="w-32 h-32">
              <CircularProgressbar
                value={0}
                text={`0%`}
                styles={buildStyles({
                  pathColor: "#4299E1",
                  textColor: "#4299E1",
                  trailColor: "#eee",
                })}
              />
            </div>
            <p className="text-gray-600 mt-4">Global Expansion</p>
            <p className="text-gray-500 mt-2">But starting with INDIA</p>
          </div>

          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="700">
            <FaBrain className="text-4xl text-green-500 mb-4 transition-transform duration-300 group-hover:scale-110" />
            <div className="w-32 h-32">
              <CircularProgressbar
                value={0}
                text={`0%`}
                styles={buildStyles({
                  pathColor: "#48BB78",
                  textColor: "#48BB78",
                  trailColor: "#eee",
                })}
              />
            </div>
            <p className="text-gray-600 mt-4">AI Personalization</p>
            <p className="text-gray-500 mt-2">Deployment commences soon</p>
          </div>
        </div>
      </section>

      <footer className="bg-gray-900 text-gray-400 py-8 px-8 mt-14 flex flex-col items-center justify-between gap-2">
              <div className="text-center">
                <p className="mb-2">&copy; {new Date().getFullYear()} TOTLE. All rights reserved.</p>
                <div className="flex justify-center space-x-6">
                  <NavLink to='/privacy-policy' onClick={handleLinkClick} className="hover:text-white transition">Privacy Policy</NavLink>
                  <NavLink to='/contact' onClick={handleLinkClick} className="hover:text-white transition">Contact Us</NavLink>
                </div>
              </div>
              <div className="text-center">
                <h4 className='mb-2'>Follow us on</h4>
                <ul className='flex gap-4 items-center justify-center list-none'>
                  <li>
                    <a href="http://www.linkedin.com/in/totle-edu-3b0792347" target="_blank" rel="noreferrer">
                      <FaLinkedin />
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/TotleEdu" target="_blank" rel="noreferrer">
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/totle_edu/?next=%2F" target="_blank" rel="noreferrer">
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61572098313677" target="_blank" rel="noreferrer">
                      <FaFacebookSquare />
                    </a>
                  </li>
                </ul>
              </div>
            </footer>
    </section>
  );
};

export default Investors;
