import React from "react";
import { Outlet } from "react-router-dom";
import LearnSidebar from "./Sidebar/LearnSidebar";
import styles from "./LearnPlatform.module.css";

const LearnPlatform: React.FC = () => {
  return (
    <div className={styles.learnPlatform}>
      {/* Navbar at the top */}

      {/* Sidebar and Main Content Layout */}
      <div className={styles.layout}>
        <LearnSidebar />
        <div className={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default LearnPlatform;
