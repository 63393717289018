import React from "react";
import { motion } from "framer-motion";

const DashboardPage: React.FC = () => {
  return (
    <div className="flex-1 flex flex-col justify-center items-center relative bg-black/80 text-white min-h-screen">
      {/* Background Overlay */}
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{
          backgroundImage: "url('/images/dashboard-bg.jpg')", // Replace with your background image
          filter: "blur(8px)",
        }}
      ></div>

      {/* Glass-like Overlay */}
      <div className="absolute inset-0 bg-black/50 backdrop-blur-md z-10"></div>

      {/* Content */}
      <div className="relative z-20 text-center px-6 mt-20 md:mt-24">
        {/* Add margin at the top to account for Navbar and Sidebar */}
        <motion.h1
          className="text-5xl sm:text-6xl font-extrabold bg-gradient-to-r from-tangering to-orange-500 bg-clip-text text-transparent mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Dashboard - Coming Soon
        </motion.h1>
        <motion.p
          className="text-lg sm:text-xl text-gray-300"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}
        >
          Analytics and trends will be available soon. Check back later!
        </motion.p>
      </div>
    </div>
  );
};

export default DashboardPage;

// Add this export statement to make it a module
export {};
